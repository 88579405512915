<script setup lang="ts">
import { cva } from 'class-variance-authority';
import { Skeletor } from 'vue-skeletor';

type PropertyImageProps = {
  imageUrl: string;
  alt?: string;
  size?: 'small' | 'thumb' | 'default' | 'large';
};

const props = withDefaults(defineProps<PropertyImageProps>(), {
  alt: '',
  size: 'default',
});

const imageLoaded = ref<boolean>(false);
const imageError = ref<boolean>(false);

const skeletorSize = computed<{ width: number; height: number }>(() => {
  switch (props.size) {
    case 'large':
      return {
        width: 182,
        height: 116,
      };

    case 'thumb':
      return {
        height: 45,
        width: 70,
      };

    case 'small':
      return {
        height: 70,
        width: 70,
      };

    default:
      return {
        width: 120,
        height: 75,
      };
  }
});

const imageClasses = cva('object-position-center object-cover', {
  variants: {
    size: {
      small: 'h-[70px] w-[70px] min-w-[70px] rounded',
      thumb: 'h-[45px] w-[70px] min-w-[70px] rounded',
      default: 'h-[75px] w-[120px] rounded',
      large: 'h-[116px] w-[182px] rounded-md',
    },
  },
});
</script>

<template>
  <div>
    <Skeletor
      v-if="!imageLoaded"
      role="status"
      :width="skeletorSize.width"
      :height="skeletorSize.height"
      class="mr-4 rounded-lg"
    />
    <img
      :class="[
        imageClasses({ size: props.size }),
        {
          hidden: !imageLoaded,
        },
      ]"
      :src="props.imageUrl"
      :alt="props.alt"
      @load="imageLoaded = true"
      @error="
        imageLoaded = true;
        imageError = true;
      "
    />
  </div>
</template>
